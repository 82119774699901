import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import Layout from '../components/Layout';
import Text from '../components/Text';

export default function IndexPage() {
  return (
    <Layout title="Hello!">
      <Text>
        I'm Steven, and for the last 10 years I've been building websites across{' '}
        <OutboundLink href="https://www.linkedin.com/in/stevenfrostwick/">
          a number of different industries
        </OutboundLink>
        .
      </Text>

      <Text>
        I ❤️ building great user experiences and I've worked at every stage of
        the software development lifecycle - taking products from conception to
        production.
      </Text>

      <Text>
        <span role="img" aria-label="Union Jack flag emoji">
          🇬🇧
        </span>{' '}
        <span role="img" aria-label="aeroplane taking off emoji">
          🛫
        </span>{' '}
        <span role="img" aria-label="aeroplane landing emoji">
          🛬
        </span>{' '}
        <span role="img" aria-label="flag of Canada emoji">
          🇨🇦
        </span>{' '}
        I'm from the UK, and currently based in Vancouver, Canada.
      </Text>

      <Text>
        <span role="img" aria-label="Memo emoji">
          📝
        </span>{' '}
        I recently started to{' '}
        <a href="/today-i-learned">blog about the little pieces of knowledge</a>{' '}
        I learn while working in software.
      </Text>

      <Text>
        <span role="img" aria-label="Headphones emoji">
          🎧
        </span>{' '}
        I built{' '}
        <OutboundLink href="https://earworms.club/">Earworms</OutboundLink> - a
        micro social media website to share your favourite track this week.
      </Text>

      <Text>
        You can see some other things I've been involved with recently on my{' '}
        <Link to="/projects">projects page</Link>.
      </Text>

      <StaticImage
        alt="Steven Frostwick on a train"
        src={'../images/steven-frostwick-on-a-train.jpg'}
        title="Steven Frostwick on a train"
      />
    </Layout>
  );
}
